export type ContextType = 'company' | 'agency';

export class IncrementalContextVersion {

  private static VERSIONS: {[key in ContextType]: number} = {
    company: 1,
    agency: 1
  };

  static nextVersion(key: ContextType): number {
    const result = IncrementalContextVersion.VERSIONS[key] + 1;
    IncrementalContextVersion.VERSIONS[key] = result;
    return result;
  }

  static isCurrentVersion(key: ContextType, version: number): boolean {
    return IncrementalContextVersion.VERSIONS[key] === version;
  }
}
